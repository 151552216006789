<template>
  <div style="font-size: 14px;" v-if="d_userShopWdm">
    <template v-if="d_loading">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </template>
    <template v-else>
      <div v-if="d_clauseList.length > 0">
        <b-row style="margin-bottom: 5px;">
          <b-col cols="1"></b-col>
          <b-col cols="10">
            <b-row>
              <b-col sm="12" lg="6">
              </b-col>
              <b-col sm="12" lg="6" style="text-align: right;">
                <i class="fa fa-print" @click="f_printAgreement()" style="font-size:22px;"></i>
              </b-col>
            </b-row>
            <hr>
          </b-col>
          <b-col cols="1"></b-col>
        </b-row>
        <template v-if="(!p_showMode || (p_showMode && p_showMode.mode !== 'approve')) && d_bundleAgreementList.length > 0">
          <b-row style="margin-bottom: 5px;">
            <b-col cols="1"></b-col>
            <b-col cols="10">
              <b-row>
                <b-col sm="12" lg="6">
                  {{ StoreLangTranslation.data['select_bundle_agreement'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="6">
                  <b-form-select v-model="d_selectedBundleAgreementIndex" @change="f_selectBundleAgreement()">
                    <option v-for="(bund_agree, bund_agree_index) in d_bundleAgreementList" :value="bund_agree_index">
                      {{ bund_agree_index + 1 }}
                      <template v-if="bund_agree.data && bund_agree.data['0'] && bund_agree.data['0']['0'] && bund_agree.data['0']['0']['val']['translation']">
                        <span> {{ bund_agree.data['0']['0']['val']['translation'][StoreLang] }} </span>
                      </template>
                      <template v-if="bund_agree.data && bund_agree.data['0'] && bund_agree.data['0']['1'] && bund_agree.data['0']['1']['lang'] && bund_agree.data['0']['1']['lang'][StoreLang]">
                        <span> {{ bund_agree.data['0']['1']['lang'][StoreLang] }} </span>
                      </template>
                      <template v-else-if="bund_agree.data && bund_agree.data['0'] && bund_agree.data['0']['1'] && bund_agree.data['0']['1']['val']">
                        <span> {{ bund_agree.data['0']['1']['val'] }} </span>
                      </template>
                    </option>
                  </b-form-select>
                </b-col>
              </b-row>      
              <hr>
            </b-col>
            <b-col cols="1"></b-col>
          </b-row>
          <b-row v-if="false" style="margin-bottom: 5px;">
            <b-col cols="1"></b-col>
            <b-col cols="10">
              <b-row>
                <b-col sm="12" lg="6">
                  {{ StoreLangTranslation.data['agreement_type'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="6">
                  <b-form-select v-model="d_agreementType" @change="f_selectBundleAgreement()">
                    <option value="all">
                      {{ StoreLangTranslation.data['all'][StoreLang] }}
                    </option>
                    <option value="active">
                      {{ StoreLangTranslation.data['active'][StoreLang] }}
                    </option>
                  </b-form-select>
                </b-col>
              </b-row>      
              <hr>
            </b-col>
            <b-col cols="1"></b-col>
          </b-row>
        </template>
        <template v-if="(!p_showMode || (p_showMode && p_showMode.mode !== 'approve')) && d_bundleProductList.length > 0">
          <b-row style="margin-bottom: 5px;">
            <b-col cols="1"></b-col>
            <b-col cols="10">
              <template v-for="(bund_pro, bund_pro_index) in d_bundleProductList">
                  <b-row v-if="bund_pro.agreement_list.length > 1">
                    <b-col sm="12" lg="6">
                      {{ StoreLangTranslation.data['select_bundle_product_agreement'][StoreLang] }}
                    </b-col>
                    <b-col sm="12" lg="6">
                      <b-form-select v-model="bund_pro.selected_agreement_index" @change="f_selectBundleProductAgreement()">
                        <option v-for="(bund_agree, bund_agree_index) in bund_pro.agreement_list" :value="bund_agree_index">
                          {{ bund_agree_index + 1 }}
                          <template v-if="bund_agree.data && bund_agree.data['0'] && bund_agree.data['0']['0'] && bund_agree.data['0']['0']['val']['translation']">
                            <span> {{ bund_agree.data['0']['0']['val']['translation'][StoreLang] }} </span>
                          </template>
                          <template v-if="bund_agree.data && bund_agree.data['0'] && bund_agree.data['0']['1'] && bund_agree.data['0']['1']['lang'] && bund_agree.data['0']['1']['lang'][StoreLang]">
                            <span> {{ bund_agree.data['0']['1']['lang'][StoreLang] }} </span>
                          </template>
                          <template v-else-if="bund_agree.data && bund_agree.data['0'] && bund_agree.data['0']['1'] && bund_agree.data['0']['1']['val']">
                            <span> {{ bund_agree.data['0']['1']['val'] }} </span>
                          </template>
                        </option>
                      </b-form-select>
                    </b-col>
                  </b-row>      
              </template>
            </b-col>
            <b-col cols="1"></b-col>
          </b-row>
        </template>
        <div ref="agreement_details">
    <!--       <template v-if="d_userShopData">
            <b-row>
              <b-col cols="11"></b-col>
              <b-col cols="1">
                <template v-if="d_userShopData.data && d_userShopData.data['0'] && d_userShopData.data['0']['2'] && d_userShopData.data['0']['2']['lang'] && d_userShopData.data['0']['2']['lang'][StoreLang]">
                  <img :src="d_userShopData.data['0']['2']['lang'][StoreLang]" style="width: 30px;">
                </template>
                <template v-else-if="d_userShopData.data && d_userShopData.data['0'] && d_userShopData.data['0']['2'] && d_userShopData.data['0']['2']['val']">
                  <img :src="usr_shp.data['0']['2']['val']" style="width: 30px;">
                </template>
              </b-col>
            </b-row>
          </template> -->
          <b-row v-if="d_bundleData" style="margin-bottom: 50px;">
            <b-col cols="1"></b-col>
            <b-col cols="10" style="text-align: center;">
              <template v-if="d_bundleData.data && d_bundleData.data['0'] && d_bundleData.data['0']['1'] && d_bundleData.data['0']['1']['lang'] && d_bundleData.data['0']['1']['lang'][StoreLang]">
                <strong> {{ d_bundleData.data['0']['1']['lang'][StoreLang] }} </strong>
              </template>
              <template v-else-if="d_bundleData.data && d_bundleData.data['0'] && d_bundleData.data['0']['1'] && d_bundleData.data['0']['1']['val']">
                <strong> {{ d_bundleData.data['0']['1']['val'] }} </strong>
              </template>
              <strong> {{ StoreLangTranslation.data['agreement'][StoreLang] }} </strong>
            </b-col>
            <b-col cols="1"></b-col>
          </b-row>
          <b-row >
            <b-col cols="1"></b-col>
            <b-col cols="10">
              <strong>
                {{ StoreLangTranslation.data['shop_info'][StoreLang] }}
              </strong>
              <hr>
            </b-col>
            <b-col cols="1"></b-col>
          </b-row>
          <b-row v-if="d_userShopData">
            <b-col cols="1"></b-col>
            <b-col cols="10">
              <table align="center" style="width: 100%; table-layout: fixed; overflow-wrap: break-word;">
                <tbody>
                  <tr>
                    <td style="width: 20%;"> <strong> {{ d_userShopWdm['0']['parameters']['1']['name']['translation'][StoreLang] }} </strong> </td>
                    <td>
                      <template v-if="d_userShopData.data && d_userShopData.data['0'] && d_userShopData.data['0']['1'] && d_userShopData.data['0']['1']['lang'] && d_userShopData.data['0']['1']['lang'][StoreLang]">
                        <span> {{ d_userShopData.data['0']['1']['lang'][StoreLang] }} </span>
                      </template>
                      <template v-else-if="d_userShopData.data && d_userShopData.data['0'] && d_userShopData.data['0']['1'] && d_userShopData.data['0']['1']['val']">
                        <span> {{ d_userShopData.data['0']['1']['val'] }} </span>
                      </template>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 20%;"> <strong> {{ d_userShopWdm['0']['parameters']['3']['name']['translation'][StoreLang] }} </strong> </td>
                    <td>
                      <template v-if="d_userShopData.data && d_userShopData.data['0'] && d_userShopData.data['0']['3'] && d_userShopData.data['0']['3']['lang'] && d_userShopData.data['0']['3']['lang'][StoreLang]">
                        <span> {{ d_userShopData.data['0']['3']['lang'][StoreLang] }} </span>
                      </template>
                      <template v-else-if="d_userShopData.data && d_userShopData.data['0'] && d_userShopData.data['0']['3'] && d_userShopData.data['0']['3']['val']">
                        <span> {{ d_userShopData.data['0']['3']['val'] }} </span>
                      </template>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 20%;"> <strong> {{ d_userShopWdm['0']['parameters']['29']['name']['translation'][StoreLang] }} </strong> </td>
                    <td>
                      <template v-if="d_userShopData.data && d_userShopData.data['0'] && d_userShopData.data['0']['29'] && d_userShopData.data['0']['29']['lang'] && d_userShopData.data['0']['29']['lang'][StoreLang]">
                        <span> {{ d_userShopData.data['0']['29']['lang'][StoreLang] }} </span>
                      </template>
                      <template v-else-if="d_userShopData.data && d_userShopData.data['0'] && d_userShopData.data['0']['29'] && d_userShopData.data['0']['29']['val']">
                        <span> {{ d_userShopData.data['0']['29']['val'] }} </span>
                      </template>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 20%;"> <strong> {{ d_userShopWdm['0']['parameters']['30']['name']['translation'][StoreLang] }} </strong> </td>
                    <td>
                      <template v-if="d_userShopData.data && d_userShopData.data['0'] && d_userShopData.data['0']['30'] && d_userShopData.data['0']['30']['lang'] && d_userShopData.data['0']['30']['lang'][StoreLang]">
                        <span> {{ d_userShopData.data['0']['30']['lang'][StoreLang] }} </span>
                      </template>
                      <template v-else-if="d_userShopData.data && d_userShopData.data['0'] && d_userShopData.data['0']['30'] && d_userShopData.data['0']['30']['val']">
                        <span> {{ d_userShopData.data['0']['30']['val'] }} </span>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </table>
              <hr>
            </b-col>
            <b-col cols="1"></b-col>
          </b-row>
          <b-row>
            <b-col cols="1"></b-col>
            <b-col cols="10">
              <strong>
                {{ StoreLangTranslation.data['customer_info'][StoreLang] }}
              </strong>
              <hr>
            </b-col>
            <b-col cols="1"></b-col>
          </b-row>
          <b-row v-if="d_user">
            <b-col cols="1"></b-col>
            <b-col cols="10">
              <table align="center" style="width: 100%; table-layout: fixed; overflow-wrap: break-word;">
                <tbody>
                  <tr>
                    <td style="width: 20%;"> <strong> {{ StoreLangTranslation.data['customer_name'][StoreLang].toLocaleLowerCase() }} </strong> </td>
                    <td>
                      {{ d_user.first_name + ' ' + d_user.last_name }}
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 20%;"> <strong> {{ StoreLangTranslation.data['customer_address'][StoreLang].toLocaleLowerCase() }} </strong> </td>
                    <td>
                      {{ d_user.address }}
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 20%;"> <strong> {{ StoreLangTranslation.data['customer_mail'][StoreLang].toLocaleLowerCase() }} </strong> </td>
                    <td>
                      {{ d_user.mail }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <hr>
            </b-col>
            <b-col cols="1"></b-col>
          </b-row>
          <b-row>
            <b-col cols="1"></b-col>
            <b-col cols="10">
              <strong>
                {{ StoreLangTranslation.data['agreement'][StoreLang] }}
              </strong>
              <hr>
            </b-col>
            <b-col cols="1"></b-col>
          </b-row>
          <template v-for="(clause, clause_index) in d_clauseList">
            <b-row>
              <b-col cols="1"></b-col>
              <b-col cols="10">
                <div :style="'margin-left: ' + (clause.itemization_level * 20) + 'px;'">
                  <strong> {{ clause.no + '. ' }} </strong>
                  <!-- <span :style="clause.type === 'product' ? 'color: green;' : ''"> {{ clause.info[StoreLang] }} </span> -->
                  <span :style="clause.no.length === 1 ? 'font-weight: 800;' : ''"> {{ clause.info[StoreLang] }} </span>
                </div>
              </b-col>
              <b-col cols="1"></b-col>
            </b-row>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>

const htmlTemplate = `
<!DOCTYPE html>
<html>
<head>
  <meta charset="utf-8">
  <title></title>
  <style type="text/css">
      @page {
        size: A4;
        margin: 10mm 8mm 10mm 8mm;
      }
    @media print {
      .new_css_class_use_test {
          width: 100%;
          display: inline-block;
        }
      .row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
      }

      .col-1 {width:8%;  float:left;}
        .col-2 {width:16%; float:left;}
        .col-3 {width:25%; float:left;}
        .col-4 {width:33%; float:left;}
        .col-5 {width:42%; float:left;}
        .col-6 {width:50%; float:left;}
        .col-7 {width:58%; float:left;}
        .col-8 {width:66%; float:left;}
        .col-9 {width:75%; float:left;}
        .col-10{width:83%; float:left;}
        .col-11{width:92%; float:left;}
        .col-12{width:100%; float:left;}

      html, body {
        width: 210mm;
        background: white!important;
        height: 297mm;
      }
      .page-header {
        
      }
      .page-footer {
        position: fixed;
        bottom: 0;
      }
      .page-body {
        font-size: 14px;
        width: 100%;
        height: 95%;
      }
    }
    .page-footer {
      font-size: 8px;
      position: fixed;
      bottom: 0;
    }
    .text-right {
      text-align: right;
    }
    table, td, tr {
      // border: 1px solid black;
    }
    .page-body {
      font-size: 14px;
      width: 100%;
      height: 95%;
    }

    ##CSS##
  </style>

</head>
<body>
  <div class="page-header">
    ##HEADER##
  </div>
  <div class="page-body">
    ##BODY##
  </div>
  <div class="page-footer">
    ##FOOTER##
  </div>
</body>
</html>
`;

import { ClsWdm } from '@/wam/modules/wdm';
import { ServiceFunctions } from '@/wam/modules/service';
import { mapGetters } from 'vuex';
import moment from "moment";

export default {
  name: 'AgreementView',
  computed: mapGetters({
    StoreLang: 'StoreLang',
    StoreLangList: 'StoreLangList',
    StoreLangTranslation: 'StoreLangTranslation',
    StoreFrontendSettings: 'StoreFrontendSettings',
    StoreDevice: 'StoreDevice',
    StoreCurrencyType: 'StoreCurrencyType',
  }),
  components: {},
  props: {
    p_showMode: {
      type: Object,
      required: false
    },
    p_shopData: {
      type: Object,
      required: false
    },
    p_bundleData: {
      type: Object,
      required: false
    },
    p_bundleProductData: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      d_loading: false,
      d_agreementType: 'all',
      d_userShopWdm: '',
      d_usrShpcrtBundWdm: '',
      d_usrShpcrtBundProWdm: '',
      d_agreementStatusType: '',
      d_bundleData: '',
      d_user: '',
      d_selectedBundleAgreementIndex: '',
      d_userShopData: '',
      d_selectedBundleAgreementData: '',
      d_selectedBundleProductAgreementData: '',
      d_clauseList: [],
      d_bundleAgreementList: [],
      d_bundleProductList: [],
    }
  },
  created: function () {
    ClsWdm.getLayerWdm('usr_shp').then(resp_wdm_data => { this.d_userShopWdm = resp_wdm_data; });
    ClsWdm.getLayerWdm('usr_shpcrt_bund').then(resp_wdm_data => { this.d_usrShpcrtBundWdm = resp_wdm_data; });
    ClsWdm.getLayerWdm('usr_shpcrt_bund_pro').then(resp_wdm_data => { this.d_usrShpcrtBundProWdm = resp_wdm_data; });
    this.d_user = JSON.parse(localStorage.getItem('user'));
    if (this.p_showMode && this.p_showMode.mode === 'approve') {
      this.d_agreementStatusType = '1';
    }
    if (this.p_bundleData && this.p_bundleData.client_id) {
      if (this.p_bundleData.agreement_client_id) {
        this.f_userShopBundleAgreementGet();
      } else {
        this.f_bundleProductAndAgreementListAll();
        // this.f_userShopBundleAgreementList();
      }
    } else if (this.p_bundleProductData && this.p_bundleProductData.client_id) {
      if (this.p_bundleProductData.agreement_client_id) {
        this.f_userShopBundleProductAgreementGet();
      } else {
        this.f_userShopBundleProductAgreementList();
      }
    }
    // if (this.p_shopData && this.p_shopData.client_id) {
    //   this.f_userShopGet();
    // }
  },
  beforeMount () {},
  mounted: function () {},
  methods: {
    f_printAgreement: function () {
      let user_all_name = '';
      if (this.d_user && this.d_user.first_name && this.d_user.last_name) {
        user_all_name = this.d_user.first_name + ' ' + this.d_user.last_name;
      }
      // console.log('window : ', window);
      let x = new Date();
      let now_date = moment(x.toString()).format('DD/MM/YYYY HH:mm');
      /*
        *** window.open usage ***
          URL Optional.
            The URL of the page to open.
            If no URL is specified, a new blank window/tab is opened
          name  Optional.
            The target attribute or the name of the window.
            The following values are supported:
            Value Description
            _blank  URL is loaded into a new window, or tab. This is the default
            _parent URL is loaded into the parent frame
            _self URL replaces the current page
            _top  URL replaces any framesets that may be loaded
            name  The name of the window (does not specify the title of the window)
          specs Optional.
            A comma-separated list of items, no whitespaces.
            The following values are supported:
          fullscreen=yes|no|1|0 Whether or not to display the browser in full-screen mode. Default is no. A window in full-screen mode must also be in theater mode. IE only
          height=pixels The height of the window. Min. value is 100
          left=pixels The left position of the window. Negative values not allowed
          location=yes|no|1|0 Whether or not to display the address field. Opera only
          menubar=yes|no|1|0  Whether or not to display the menu bar
          resizable=yes|no|1|0  Whether or not the window is resizable. IE only
          scrollbars=yes|no|1|0 Whether or not to display scroll bars. IE, Firefox & Opera only
          status=yes|no|1|0 Whether or not to add a status bar
          titlebar=yes|no|1|0 Whether or not to display the title bar. Ignored unless the calling application is an HTML Application or a trusted dialog box
          toolbar=yes|no|1|0  Whether or not to display the browser toolbar. IE and Firefox only
          top=pixels  The top position of the window. Negative values not allowed
          width=pixels  The width of the window. Min. value is 100
      */
      let w = window.open('', '_blank', 'height=750, width=1000, toolbar=0, menubar=0, left=100, top=30');
      let pageHtml = htmlTemplate;
      // pageHtml = pageHtml.replace('##HEADER##', 'Sözleşme');
      pageHtml = pageHtml.replace('##HEADER##', '');
      let body_html = '<div style="padding: 10px;">';
      body_html += this.$refs.agreement_details.innerHTML;
      body_html += '</div>';
      pageHtml = pageHtml.replace('##BODY##', body_html);
      if (user_all_name) {
        pageHtml = pageHtml.replace('##FOOTER##', user_all_name + ' ' + now_date);
      } else {
        pageHtml = pageHtml.replace('##FOOTER##', now_date);
      }
      w.document.write(pageHtml);
      w.document.close();
      w.onload = function () {
        w.focus();
        w.print();
      };
    },
    f_selectBundleAgreement: function () {
      this.d_selectedBundleAgreementData = this.d_bundleAgreementList[this.d_selectedBundleAgreementIndex];
      this.f_prepareClauseList();
    },
    f_selectBundleProductAgreement: function () {
      this.f_prepareClauseList();
    },
    f_prepareClauseList: function () {
      this.d_clauseList = [];
      let last_item_level_numbers = {};
      /*
        last_item_level_numbers = {
          '0': [1],
          '1': [1,1]
        }
      */
      if (this.d_selectedBundleAgreementData) {
        // paket sözleşmesi olduğundan ürün listesi ile birlikte sözleşme maddelerini düzenliyoruz.
        if (this.d_selectedBundleAgreementData.data['0']['4'] && this.d_selectedBundleAgreementData.data['0']['4'].list) {
          for (let i in this.d_selectedBundleAgreementData.data['0']['4'].list) {
            let clause_item = this.d_selectedBundleAgreementData.data['0']['4'].list[i];
            last_item_level_numbers = this.f_clauseItemProcess(clause_item, last_item_level_numbers, '0', 'bundle', '');
          }
        }
        for (let product_index in this.d_bundleProductList) {
          let selected_agreement_index = this.d_bundleProductList[product_index].selected_agreement_index;
          // ürüne sözleşme tanımlanmadıysa agreement_list boş olabilir. Bu nedenle öncelikle kontrol ediyoruz.
          if (this.d_bundleProductList[product_index]['agreement_list'][selected_agreement_index]) {
            let product_agreement_data = this.d_bundleProductList[product_index]['agreement_list'][selected_agreement_index];
            // console.log('product_agreement_data ', product_agreement_data);
            for (let i in product_agreement_data.data['0']['4'].list) {
              let clause_item = product_agreement_data.data['0']['4'].list[i];
              last_item_level_numbers = this.f_clauseItemProcess(clause_item, last_item_level_numbers, '', 'product', this.d_bundleProductList[product_index].product);
            }
          }
        }
        if (this.d_selectedBundleAgreementData.data['0']['4'] && this.d_selectedBundleAgreementData.data['0']['4'].list) {
          for (let i in this.d_selectedBundleAgreementData.data['0']['4'].list) {
            let clause_item = this.d_selectedBundleAgreementData.data['0']['4'].list[i];
            last_item_level_numbers = this.f_clauseItemProcess(clause_item, last_item_level_numbers, '1', 'bundle', '');
          }
        }
      } else if (this.d_selectedBundleProductAgreementData) {
        for (let i in this.d_selectedBundleProductAgreementData.data['0']['4'].list) {
          let clause_item = this.d_selectedBundleProductAgreementData.data['0']['4'].list[i];
          last_item_level_numbers = this.f_clauseItemProcess(clause_item, last_item_level_numbers, '', 'product', '');
        }
      }
    },
    f_clauseItemProcess: function (clause_item, last_item_level_numbers, order_type, agreement_type, product_data) {
      /*
        {
          "5": {"val": {"value": "1"} }, maddelendirme seviyesi
          "6": {"lang": {"en": "x", "tr": "x"}, "val": "x"} maddenin açıklaması
          "7": {"val": {"value": "0"} }, => ürünlerle sıralama 0 öncesi 1 sonrası anlamında
        }
      */
      let will_continue = false;
      /*
        '0' ürün öncesi
        '1' ürün sonrası
      */
      let pro_order_type = '';
      try {
        pro_order_type = clause_item['7']['val']['value'];
      } catch(err) {}
      if (order_type) {
        if (order_type === pro_order_type) {
          will_continue = true;
        }
      } else {
        will_continue = true;
      }
      if (will_continue) {
        let itemization_level = 0;
        try {
          itemization_level = parseInt(clause_item['5']['val']['value']);
        } catch(err) {}

        let clause_info = '';
        try {
          clause_info = clause_item['6']['lang'];
        } catch(err) {}

        /*
          console.log('itemization_level                          : ', itemization_level);
          console.log('last_item_level_numbers[itemization_level] : ', last_item_level_numbers[itemization_level]);
          console.log('last_item_level_numbers : ', JSON.stringify(last_item_level_numbers));
        */

        if (last_item_level_numbers[itemization_level] === undefined) {
          if (last_item_level_numbers[itemization_level - 1] === undefined) {
            last_item_level_numbers[itemization_level] = [1];
          } else {
            last_item_level_numbers[itemization_level] = JSON.parse(JSON.stringify(last_item_level_numbers[itemization_level - 1]));
            last_item_level_numbers[itemization_level].push(1);
          }
        } else {
          let last_index = last_item_level_numbers[itemization_level].length - 1;
          last_item_level_numbers[itemization_level][last_index] = last_item_level_numbers[itemization_level][last_index] + 1;
        }
        let new_clause_item = {
          'type': agreement_type
        };
        if (last_item_level_numbers[itemization_level]) {
          new_clause_item['no'] = last_item_level_numbers[itemization_level].join('.');
        }
        new_clause_item['itemization_level'] = itemization_level;
        if (clause_info) {
          /*
            __agreement_count__ Sözleşme adedi  2 (iki)
            __agreement_end_date__  Sözleşme bitiş tarihi 2023-12-3
            __agreement_signature_date__  Sözleşme imzalama tarihi  2022-12-4
            __agreement_start_date__  Sözleşme başlama tarihi 2022-12-3
            __attachment_count__  Sözleşme ek adedi 2 (iki)
            __bundle_expiration_phrase__  paket geçerlilik süresi ifadesi 3 parametre değeri birleştirerek yazılır. 1 (bir) yıl
            __bundle_expiration_type__  paket geçerlilik süre tipi  yıl
            __bundle_expiration_value__ paket geçerlilik süre değeri  1
            __bundle_expiration_value_as_text__ paket geçerlilik süre değeri yazı ile bir
            __bundle_features__ paket veri modelindeki özellik listesi buraya maddelendirilerek yerleştirilir 
            __bundle_name__ paket adı 
            __bundle_price__  paket içindeki ürünlerin toplam fiyatını ifade etmektedir. TL ya da dolar olması kişinin hangi kuru seçtiğine göre değişerek ekrandan otomatik oluşturulacaktır.  300000 TL
            __bundle_product_about__  ürün hakkında parametresi içeriği buraya yazılır  
            __bundle_product_expiration_phrase__  paket ürünü geçerlilik süresi ifadesi 3 parametre değeri birleştirerek yazılır. 3 (üç) ay
            __bundle_product_expiration_type__  paket ürünü geçerlilik süre tipi  ay
            __bundle_product_expiration_value__ paket ürünü geçerlilik süre değeri  3
            __bundle_product_expiration_value_as_text__ paket ürünü geçerlilik süre değeri yazı ile üç
            __bundle_product_name__ ürün adını ifade eder whichclinic, kurumsal abonelik, yıllık lisans
            __bundle_product_price__  ürün hizmet bedeli buraya yazılır. Seçim yaptığı döviz tipine dikkat edilir 
            __bundle_product_price_includes_taxes__ Paket ürünlerinin fiyatlarının vergiler dahil olma durumu vergiler dahil, vergiler hariç şeklinde fiyatın yanında parantez içinde
            __clause_count__  Sözleşme madde adedi  20 (yirmi)
            __clause_page__ Sözleşme sayfa adedi  10 (on)
            __company_abbr__  Şirket kısa adı WisdomEra
            __company_address__ Şirket adresi Ayazağa Mahallesi, Kemerburgaz Cad. Vadi İstanbul Park Sitesi, 7a Blok No: 7b İç Kapı No: 4 Sarıyer / İstanbul
            __company_mail__  şirket mail 
            __company_name__  Şirket uzun adı WisdomEra Yazılım Sağlık Teknoloji Anonim Şirketi
            __company_phone_number__  şirket telefon no 
            __company_trade_register_number__ ticaret sicil no  
            __customer_name__ Müşteri adı Levent Korkmaz
            __shop_mersis_number__  mersis no 
          */

          /*
            kullanıcı / mağaza / paket / ürün Parametreleri
            0       select  durum
            1       text    adı
            2       text    client id
            3       select  atama yapabilir mi
            4       text    yetki kodu
            5       textarea    hakkında
            6       datetime    başlama tarihi
            7       datetime    bitiş tarihi
            8       text    grup adı
            9       integer kullanım adedi
            10      text    adet son ek
            11      integer ödeme gecikme maksimum gün
            12      text    organizasyon id
            13      text    organizasyon adı
            14      text    organizasyon kullanıcı id
            15      text    organizasyon kullanıcı adı
            16      select  hazırlama tipi
            17      select  organizasyon görev tipi
            18      select  organizasyon önceliği
            19      select  ödeme tipi
            20      select  ödeme period tipi
            21      select  ödeme kredi kartı ile
            22      select  ödeme hesaba transfer ile
            23      select  kullanım adedi tipi
            24      select  para birimi tipi
            25      list_parameter  döviz para değeri
            26  25  select  döviz tipi
            27  25  float   tutar
            28      float   vergi oranı yüzdesi
            29      select  paket ürün listesinde görünme durumu
            30      select  sona erme süre tipi
            31      integer sona erme süre değeri
            32      text    sona erme süre değeri yazı ile
            33      select  fiyat vergiler dahil durumu
            34      textarea    organizasyon token
          */

          for (let lang_value in clause_info) {
            if (product_data) {
              let will_use_product = 'yes';
              /*
                if (this.d_agreementType === 'all' || (product_data.data['0']['0']['val']['value'] === '1' && this.d_agreementType === 'active')) {
                  will_use_product = 'yes';
                }
              */
              if (will_use_product === 'yes') {
                // __bundle_product_about__ ürün adını ifade eder whichclinic, kurumsal abonelik, yıllık lisans
                let __bundle_product_about__ = '';
                try {
                  __bundle_product_about__ = product_data.data['0']['5']['lang'][lang_value];
                } catch(err) {}
                if (clause_info[lang_value].indexOf('__bundle_product_about__') !== -1) {
                  clause_info[lang_value] = clause_info[lang_value].replaceAll('__bundle_product_about__', __bundle_product_about__);
                }

                // __bundle_product_name__ ürün adını ifade eder whichclinic, kurumsal abonelik, yıllık lisans
                let __bundle_product_name__ = '';
                try {
                  __bundle_product_name__ = product_data.data['0']['1']['lang'][lang_value];
                } catch(err) {}
                if (clause_info[lang_value].indexOf('__bundle_product_name__') !== -1) {
                  clause_info[lang_value] = clause_info[lang_value].replaceAll('__bundle_product_name__', __bundle_product_name__);
                }

                // __bundle_product_expiration_phrase__  paket ürünü geçerlilik süresi ifadesi 3 parametre değeri birleştirerek yazılır. 3 (üç) ay
                let __bundle_product_expiration_phrase__ = '';
                try {
                  let expiration_time_type_label = this.f_getOptionLabel(product_data.data['0']['30']['val']['value'], this.d_usrShpcrtBundProWdm['0']['parameters']['30']['options']);
                  __bundle_product_expiration_phrase__ = product_data.data['0']['31']['lang'][lang_value] + ' (' + product_data.data['0']['32']['lang'][lang_value] + ') ' + expiration_time_type_label;
                } catch(err) {}
                if (clause_info[lang_value].indexOf('__bundle_product_expiration_phrase__') !== -1) {
                  clause_info[lang_value] = clause_info[lang_value].replaceAll('__bundle_product_expiration_phrase__', __bundle_product_expiration_phrase__);
                }

                // __bundle_product_expiration_type__  paket ürünü geçerlilik süre tipi  ay
                let __bundle_product_expiration_type__ = '';
                try {
                  __bundle_product_expiration_type__ = this.f_getOptionLabel(product_data.data['0']['30']['val']['value'], this.d_usrShpcrtBundProWdm['0']['parameters']['30']['options']);
                } catch(err) {}
                if (clause_info[lang_value].indexOf('__bundle_product_expiration_type__') !== -1) {
                  clause_info[lang_value] = clause_info[lang_value].replaceAll('__bundle_product_expiration_type__', __bundle_product_expiration_type__);
                }

                // __bundle_product_expiration_value__ paket ürünü geçerlilik süre değeri  3
                let __bundle_product_expiration_value__ = '';
                try {
                  __bundle_product_expiration_value__ = product_data.data['0']['31']['lang'][lang_value];
                } catch(err) {}
                if (clause_info[lang_value].indexOf('__bundle_product_expiration_value__') !== -1) {
                  clause_info[lang_value] = clause_info[lang_value].replaceAll('__bundle_product_expiration_value__', __bundle_product_expiration_value__);
                }

                // __bundle_product_expiration_value_as_text__ paket ürünü geçerlilik süre değeri yazı ile üç
                let __bundle_product_expiration_value_as_text__ = '';
                try {
                  __bundle_product_expiration_value_as_text__ = product_data.data['0']['32']['lang'][lang_value];
                } catch(err) {}
                if (clause_info[lang_value].indexOf('__bundle_product_expiration_value_as_text__') !== -1) {
                  clause_info[lang_value] = clause_info[lang_value].replaceAll('__bundle_product_expiration_value_as_text__', __bundle_product_expiration_value_as_text__);
                }

                // __bundle_product_price_includes_taxes__ Paket ürünlerinin fiyatlarının vergiler dahil olma durumu vergiler dahil, vergiler hariç şeklinde fiyatın yanında parantez içinde
                let __bundle_product_price_includes_taxes__ = '';
                try {
                  __bundle_product_price_includes_taxes__ = this.f_getOptionLabel(product_data.data['0']['33']['val']['value'], this.d_usrShpcrtBundProWdm['0']['parameters']['33']['options']);
                } catch(err) {}
                if (clause_info[lang_value].indexOf('__bundle_product_price_includes_taxes__') !== -1) {
                  clause_info[lang_value] = clause_info[lang_value].replaceAll('__bundle_product_price_includes_taxes__', __bundle_product_price_includes_taxes__);
                }

                // __bundle_product_price__  ürün hizmet bedeli buraya yazılır. Seçim yaptığı döviz tipine dikkat edilir 
                let __bundle_product_price__ = '';
                try {
                  for (let cur_index in product_data.data['0']['25']['list']) {
                    if (product_data.data['0']['25']['list'][cur_index]['26']['val']['value'] === this.StoreCurrencyType.value) {
                      __bundle_product_price__ = product_data.data['0']['25']['list'][cur_index]['27']['val'] + ' ' + this.StoreCurrencyType.label;
                      break;
                    }
                  }
                } catch(err) {}
                if (clause_info[lang_value].indexOf('__bundle_product_price__') !== -1) {
                  clause_info[lang_value] = clause_info[lang_value].replaceAll('__bundle_product_price__', __bundle_product_price__);
                }
              }

            }
            if (this.d_selectedBundleAgreementData) {
              // __bundle_features__ paket veri modelindeki özellik listesi buraya maddelendirilerek yerleştirilir. Şu an için madde halinde değil aralarına boşluk konulan cümleler şeklinde ilerliyoruz.
              let __bundle_features__ = '';
              try {
                for (let feature_index in this.d_bundleData.data['0']['2']['list']) {
                  // Sözleşmeye konulabilecek özellik tiplerini koyuyoruz.
                  /*
                    13 / kullanıcı / mağaza / paket / özellik tipi
                      0 genel
                      1 anahtar bilgi
                      2 müşteri deneyimi
                      3 bağlantı
                      4 kim kullanabilir
                  */
                  if (['0'].indexOf(this.d_bundleData.data['0']['2']['list'][feature_index]['11']['val']['value']) !== -1) {
                    // let feature_caption = this.d_bundleData.data['0']['2']['list'][feature_index]['3']['val'];
                    let feature_caption = this.d_bundleData.data['0']['2']['list'][feature_index]['3']['lang'][lang_value];
                    // console.log('feature_caption :', feature_caption);
                    if (__bundle_features__) {
                      if (__bundle_features__[__bundle_features__.length - 1] !== ' ') {
                        __bundle_features__ += ' ';
                      }
                    }
                    __bundle_features__ += feature_caption;
                  }
                }
              } catch(err) {}
              if (clause_info[lang_value].indexOf('__bundle_features__') !== -1) {
                clause_info[lang_value] = clause_info[lang_value].replaceAll('__bundle_features__', __bundle_features__);
              }

              // __bundle_expiration_phrase__ paket geçerlilik süresi ifadesi 3 parametre değeri birleştirerek yazılır. 1 (bir) yıl
              let __bundle_expiration_phrase__ = '';
              try {
                let expiration_time_type_label = this.f_getOptionLabel(this.d_selectedBundleAgreementData.data['0']['14']['val']['value'], this.d_usrShpcrtBundWdm['0']['parameters']['14']['options']);
                __bundle_expiration_phrase__ = this.d_selectedBundleAgreementData.data['0']['15']['lang'][lang_value] + ' (' + this.d_selectedBundleAgreementData.data['0']['16']['lang'][lang_value] + ') ' + expiration_time_type_label;
              } catch(err) {}
              if (clause_info[lang_value].indexOf('__bundle_expiration_phrase__') !== -1) {
                clause_info[lang_value] = clause_info[lang_value].replaceAll('__bundle_expiration_phrase__', __bundle_expiration_phrase__);
              }

              // __bundle_expiration_type__ paket geçerlilik süre tipi  yıl
              let __bundle_expiration_type__ = '';
              try {
                __bundle_expiration_type__ = this.f_getOptionLabel(this.d_selectedBundleAgreementData.data['0']['14']['val']['value'], this.d_usrShpcrtBundWdm['0']['parameters']['14']['options']);
              } catch(err) {}
              if (clause_info[lang_value].indexOf('__bundle_expiration_type__') !== -1) {
                clause_info[lang_value] = clause_info[lang_value].replaceAll('__bundle_expiration_type__', __bundle_expiration_type__);
              }

              // __bundle_expiration_value__ paket geçerlilik süre değeri  1
              let __bundle_expiration_value__ = '';
              try {
                __bundle_expiration_value__ = this.d_selectedBundleAgreementData.data['0']['15']['lang'][lang_value];
              } catch(err) {}
              if (clause_info[lang_value].indexOf('__bundle_expiration_value__') !== -1) {
                clause_info[lang_value] = clause_info[lang_value].replaceAll('__bundle_expiration_value__', __bundle_expiration_value__);
              }

              // __bundle_expiration_value_as_text__ paket geçerlilik süre değeri  1
              let __bundle_expiration_value_as_text__ = '';
              try {
                __bundle_expiration_value_as_text__ = this.d_selectedBundleAgreementData.data['0']['16']['lang'][lang_value];
              } catch(err) {}
              if (clause_info[lang_value].indexOf('__bundle_expiration_value_as_text__') !== -1) {
                clause_info[lang_value] = clause_info[lang_value].replaceAll('__bundle_expiration_value_as_text__', __bundle_expiration_value_as_text__);
              }

              // __bundle_name__ paket adı
              let __bundle_name__ = '';
              try {
                __bundle_name__ = this.d_bundleData.data['0']['1']['lang'][lang_value];
              } catch(err) {}
              if (clause_info[lang_value].indexOf('__bundle_name__') !== -1) {
                clause_info[lang_value] = clause_info[lang_value].replaceAll('__bundle_name__', __bundle_name__);
              }

              // __bundle_name__ paket içindeki ürünlerin toplam fiyatını ifade etmektedir. TL ya da dolar olması kişinin hangi kuru seçtiğine göre değişerek ekrandan otomatik oluşturulacaktır.  300000 TL
              let __bundle_price__ = '';
              try {
                __bundle_price__ = this.f_calculateProductTotalPrice();
              } catch(err) {}
              if (clause_info[lang_value].indexOf('__bundle_price__') !== -1) {
                clause_info[lang_value] = clause_info[lang_value].replaceAll('__bundle_price__', __bundle_price__);
              }

            }
            // __company_name__  Şirket uzun adı WisdomEra Yazılım Sağlık Teknoloji Anonim Şirketi
            let __company_name__ = '';
            try {
              __company_name__ = this.d_userShopData.data['0']['1']['lang'][lang_value];
            } catch(err) {}
            if (clause_info[lang_value].indexOf('__company_name__') !== -1) {
              clause_info[lang_value] = clause_info[lang_value].replaceAll('__company_name__', __company_name__);
            }
            // console.log('__company_name__ :', __company_name__);
            // __company_abbr__  Şirket kısa adı WisdomEra
            let __company_abbr__ = '';
            try {
              __company_abbr__ = this.d_userShopData.data['0']['31']['lang'][lang_value];
            } catch(err) {}
            if (clause_info[lang_value].indexOf('__company_abbr__') !== -1) {
              clause_info[lang_value] = clause_info[lang_value].replaceAll('__company_abbr__', __company_abbr__);
            }
            // console.log('__company_abbr__ :', __company_abbr__);
            // __company_address__ Şirket adresi Ayazağa Mahallesi, Kemerburgaz Cad. Vadi İstanbul Park Sitesi, 7a Blok No: 7b İç Kapı No: 4 Sarıyer / İstanbul
            let __company_address__ = '';
            try {
              __company_address__ = this.d_userShopData.data['0']['3']['lang'][lang_value];
            } catch(err) {}
            if (clause_info[lang_value].indexOf('__company_address__') !== -1) {
              clause_info[lang_value] = clause_info[lang_value].replaceAll('__company_address__', __company_address__);
            }
            // __company_mail__ şirket mail
            let __company_mail__ = '';
            try {
              __company_mail__ = this.d_userShopData.data['0']['29']['lang'][lang_value];
            } catch(err) {}
            if (clause_info[lang_value].indexOf('__company_mail__') !== -1) {
              clause_info[lang_value] = clause_info[lang_value].replaceAll('__company_mail__', __company_mail__);
            }
            // __company_phone_number__ şirket telefon
            let __company_phone_number__ = '';
            try {
              __company_phone_number__ = this.d_userShopData.data['0']['30']['lang'][lang_value];
            } catch(err) {}
            if (clause_info[lang_value].indexOf('__company_phone_number__') !== -1) {
              clause_info[lang_value] = clause_info[lang_value].replaceAll('__company_phone_number__', __company_phone_number__);
            }
            // __customer_name__ müşteri adı
            let __customer_name__ = '';
            try {
              __customer_name__ = this.d_user.first_name + ' ' + this.d_user.last_name;
            } catch(err) {}
            if (clause_info[lang_value].indexOf('__customer_name__') !== -1) {
              clause_info[lang_value] = clause_info[lang_value].replaceAll('__customer_name__', __customer_name__);
            }
            // __customer_name__ müşteri adresi
            let __customer_address__ = '';
            try {
              __customer_address__ = this.d_user.address;
            } catch(err) {}
            if (clause_info[lang_value].indexOf('__customer_address__') !== -1) {
              clause_info[lang_value] = clause_info[lang_value].replaceAll('__customer_address__', __customer_address__);
            }
            // __shop_mersis_number__ mersis no 
            let __shop_mersis_number__ = '';
            try {
              __shop_mersis_number__ = this.d_userShopData.data['0']['6']['lang'][lang_value];
            } catch(err) {}
            if (clause_info[lang_value].indexOf('__shop_mersis_number__') !== -1) {
              clause_info[lang_value] = clause_info[lang_value].replaceAll('__shop_mersis_number__', __shop_mersis_number__);
            }
            // __company_trade_register_number__ ticaret sicil no
            let __company_trade_register_number__ = '';
            try {
              __company_trade_register_number__ = this.d_userShopData.data['0']['32']['lang'][lang_value];
            } catch(err) {}
            if (clause_info[lang_value].indexOf('__company_trade_register_number__') !== -1) {
              clause_info[lang_value] = clause_info[lang_value].replaceAll('__company_trade_register_number__', __company_trade_register_number__);
            }
            //
            let x = new Date();
            let __contract_signature_date__ = '';
            if (lang_value === 'tr') {
              __contract_signature_date__ = moment(x.toString()).format('DD/MM/YYYY');
            } else {
              __contract_signature_date__ = moment(x.toString()).format('YYYY/MM/DD');
            }

            if (clause_info[lang_value].indexOf('__contract_signature_date__') !== -1) {
              clause_info[lang_value] = clause_info[lang_value].replaceAll('__contract_signature_date__', __contract_signature_date__);
            }
          }
          new_clause_item['info'] = clause_info;
        }
        this.d_clauseList.push(new_clause_item);
        if (itemization_level === 0) {
          let new_last_item_level_numbers = {};
          new_last_item_level_numbers[0] = JSON.parse(JSON.stringify(last_item_level_numbers[0]));
          last_item_level_numbers = JSON.parse(JSON.stringify(new_last_item_level_numbers))
        }
      }
      return last_item_level_numbers;
    },
    f_calculateProductTotalPrice: function () {
      let currency_text = this.StoreCurrencyType.label;
      let total_amount = 0;
      for (let product_index in this.d_bundleProductList) {
        try {
          for (let currency_amount_index in this.d_bundleProductList[product_index].product.data['0']['25'].list) {
            let currency_amount_item = this.d_bundleProductList[product_index].product.data['0']['25'].list[currency_amount_index];
            try {
              if (currency_amount_item['26']['val']['value'] === this.StoreCurrencyType.value) {
                total_amount += parseFloat(currency_amount_item['27']['val']);
              }
            } catch(err) {}
          }
        } catch(err) {}
      }
      return total_amount + ' ' + currency_text;
    },
    f_getOptionLabel: function (option_value, options) {
      // console.log('option_value : ', option_value);
      // console.log('options : ', options);
      let option_label = '';
      for (let i in options) {
        if (options[i].value === option_value) {
          option_label = options[i].translation[this.StoreLang];
          break;
        }
      }
      return option_label;
    },
    f_userShopGet: function () {
      let query = 'user_shop_client_id=' + this.p_shopData.client_id;
      query += '&data_type=wdmr_mode';
      let data = {};
      ServiceFunctions.user_shop_get(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            if (resp.data.data) {
              this.d_userShopData = resp.data.data;
            } else {
              alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
            }
          } else {
            alert(resp.data.status_code + ' = ' + resp.data.status_message);
          }
        });
    },
    f_userShopBundleAgreementGet: function () {
      this.d_loading = true;
      let query = 'user_shop_client_id=' + this.p_shopData.client_id;
      query += '&data_type=wdmr_mode';
      let data = {};
      ServiceFunctions.user_shop_get(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            if (resp.data.data) {
              this.d_userShopData = resp.data.data;
              let query2 = 'user_shop_bundle_agreement_client_id=' + this.p_bundleData.agreement_client_id;
              query2 += '&data_type=wdmr_mode';
              let data2 = {};
              ServiceFunctions.user_shop_bundle_agreement_get(query2, data2)
                .then(resp => {
                  this.d_loading = false;
                  if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
                    if (resp.data.data) {
                      this.d_selectedBundleAgreementData = resp.data.data;
                      this.f_prepareClauseList();
                    } else {
                      alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
                    }
                  } else {
                    alert(resp.data.status_code + ' = ' + resp.data.status_message);
                  }
                });
            } else {
              alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
            }
          } else {
            alert(resp.data.status_code + ' = ' + resp.data.status_message);
          }
        });
    },
    f_userShopBundleProductAgreementGet: function () {
      this.d_loading = true;
      let query = 'user_shop_client_id=' + this.p_shopData.client_id;
      query += '&data_type=wdmr_mode';
      let data = {};
      ServiceFunctions.user_shop_get(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            if (resp.data.data) {
              this.d_userShopData = resp.data.data;
              let query2 = 'user_shop_bundle_product_agreement_client_id=' + this.p_bundleProductData.agreement_client_id;
              query2 += '&data_type=wdmr_mode';
              let data2 = {};
              ServiceFunctions.user_shop_bundle_product_agreement_get(query2, data2)
                .then(resp => {
                  this.d_loading = false;
                  if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
                    if (resp.data.data) {
                      this.d_selectedBundleProductAgreementData = resp.data.data;
                      this.f_prepareClauseList();
                    } else {
                      alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
                    }
                  } else {
                    alert(resp.data.status_code + ' = ' + resp.data.status_message);
                  }
                });
            } else {
              alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
            }
          } else {
            alert(resp.data.status_code + ' = ' + resp.data.status_message);
          }
        });
    },
    f_bundleProductAndAgreementListAll: function () {
      this.d_loading = true;
      let query = 'user_shop_client_id=' + this.p_shopData.client_id;
      query += '&data_type=wdmr_mode';
      let data = {};
      ServiceFunctions.user_shop_get(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            if (resp.data.data) {
              this.d_userShopData = resp.data.data;
              let query2 = 'user_shop_bundle_client_id=' + this.p_bundleData.client_id;
              if (this.d_agreementStatusType) {
                query2 += '&agreement_status_type=' + this.d_agreementStatusType;
              }
              let data2 = {};
              ServiceFunctions.bundle_product_and_agreement_list_all(query2, data2)
                .then(resp => {
                  this.d_loading = false;
                  if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
                    this.d_bundleData = resp.data.bundle_data;
                    this.d_bundleAgreementList = resp.data.bundle_agreement_list;
                    this.d_bundleProductList = resp.data.product_list;
                    for (let i in this.d_bundleProductList) {
                      this.d_bundleProductList[i].selected_agreement_index = 0;
                    }
                    this.d_selectedBundleAgreementData = this.d_bundleAgreementList[0];
                    this.f_prepareClauseList();
                  } else {
                    alert(resp.data.status_code + ' = ' + resp.data.status_message);
                  }
                });
            } else {
              alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
            }
          } else {
            alert(resp.data.status_code + ' = ' + resp.data.status_message);
          }
        });
    },
    f_userShopBundleAgreementList: function () {
      let query = 'user_shop_bundle_client_id=' + this.p_bundleData.client_id;
      let data = {};
      ServiceFunctions.user_shop_bundle_agreement_list(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            if (resp.data.list) {
              this.d_bundleAgreementList = resp.data.list;
              this.d_selectedBundleAgreementData = this.d_bundleAgreementList[0];
              this.f_prepareClauseList();
            } else {
              alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
            }
          } else {
            alert(resp.data.status_code + ' = ' + resp.data.status_message);
          }
        });
    },
    f_userShopBundleProductAgreementList: function () {
      this.d_loading = true;
      let query = 'user_shop_client_id=' + this.p_shopData.client_id;
      query += '&data_type=wdmr_mode';
      let data = {};
      ServiceFunctions.user_shop_get(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            if (resp.data.data) {
              this.d_userShopData = resp.data.data;
              let query2 = 'user_shop_bundle_product_client_id=' + this.p_bundleProductData.client_id;
              let data2 = {};
              ServiceFunctions.user_shop_bundle_product_agreement_list(query2, data2)
                .then(resp => {
                  this.d_loading = false;
                  if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
                    if (resp.data.list) {
                      this.d_bundleProductList = [
                        {
                          'selected_agreement_index': 0,
                          'agreement_list': resp.data.list,
                          'product': '',
                        }
                      ];
                      this.d_selectedBundleProductAgreementData = this.d_bundleProductList[0].agreement_list[0];
                      this.f_prepareClauseList();
                    } else {
                      alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
                    }
                  } else {
                    alert(resp.data.status_code + ' = ' + resp.data.status_message);
                  }
                });
            } else {
              alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
            }
          } else {
            alert(resp.data.status_code + ' = ' + resp.data.status_message);
          }
        });
    },
  },
  watch: {}
}

</script>

<style type="text/css">


</style>

